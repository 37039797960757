<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-03-22 18:14:02
 * @LastEditTime: 2023-07-04 13:53
 * @Descripttion: 学情分析
-->
<style lang="scss" scoped>
.study-analysis {
    @include innerPage;
    @include pageHead(-10px);
    @include pageTab;
    @include defalutTable(calc(100% - 248px), 38px);
    @include pageFoot;

    .page-head {
        overflow: visible;

        .head-wrapper.column {
            margin-top: -2px;

            .wrap-plate {
                margin-top: 0px;

                .page-tab {
                    width: auto;
                    border-bottom: none;
                    margin-left: 12.76%;
                    justify-content: flex-start;
                }

                .more-subject {
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    background: #6c4ecb;
                    margin-right: auto;
                    margin-left: 60px;
                    margin-top: 6px;
                    cursor: pointer;

                    &:hover {
                        background: #8971d5;
                    }

                    .iconfont {
                        font-size: 18px;
                        text-align: center;
                        line-height: 24px;
                        color: #fff;
                    }
                }
            }
        }
    }

    .page-inner {
        width: calc(100% + 20px);
        height: calc(100% - 202px);
        box-sizing: border-box;
        padding-right: 20px;
        margin-top: 40px;
        overflow: hidden;
        overflow-y: auto;

        .inner-box {
            width: 100%;
            @include flexBox;
        }

        .ranking {
            &-wrapper {
                width: 37%;
                height: 982px;
                max-width: 620px;
                background: #f0f3fa;
                border-radius: 10px;
            }

            &-table {
                width: 100%;
                height: calc(100% - 72px);

                .table-head {
                    height: 100%;
                    box-sizing: border-box;
                    padding-left: 29.94%;
                    margin: 4px 0;
                    @include flexBox(space-between);

                    &--wrapper {
                        width: 100%;
                        height: 72px;
                        box-sizing: border-box;
                        padding: 0 34px;
                    }

                    li {
                        width: 28.5%;
                        cursor: pointer;
                        color: #484848;
                        @include flexBox(center);
                    }

                    .sort {
                        display: inline-flex;
                        font-size: 18px;
                        color: rgba($color: #303030, $alpha: .51);

                        i:last-child {
                            margin-left: -11px;
                        }

                        &.asc i:last-child {
                            color: #6340c8;
                        }

                        &.desc i:first-child {
                            color: #6340c8;
                        }
                    }
                }

                .table-body {
                    &--wrapper {
                        width: 100%;
                        height: calc(100% - 80px);
                        overflow: hidden;
                        overflow-y: auto;
                        box-sizing: border-box;
                        padding: 0 34px;
                    }

                    .body-column {
                        width: 100%;
                    }

                    .table-tr {
                        width: 100%;
                        height: 136px;
                        border-bottom: 1px solid rgba($color: #6b6b6b, $alpha: .1);
                        color: #6b6b6b;
                        @include flexBox;

                        b {
                            font-weight: normal;
                        }

                        .user {
                            width: 29.94%;
                            height: 100%;
                            @include flexBox;

                            p {
                                width: calc(100% - 70px);
                                margin-left: 8px;
                                flex-direction: column;
                                line-height: 24px;
                                @include flexBox(center, flex-start);

                                .name {
                                    width: 100%;
                                    font-size: 16px;
                                    margin-bottom: 2px;
                                }

                                .pf_bold {
                                    font-size: 20px;
                                }
                            }
                        }

                        .study {
                            flex-grow: 1;
                            @include flexBox(space-between);

                            span {
                                display: inline-block;
                                width: 28.5%;
                                text-align: center;
                                font-size: 20px;
                            }

                            .level {
                                width: auto;
                                flex-grow: 1;
                                @include flexBox(center);

                                span {
                                    font-size: 18px;
                                    margin-left: 6px;
                                    padding-top: 2px;
                                    width: auto;
                                }
                            }
                        }
                    }
                }
            }
        }

        .right-box {
            width: calc(63% - 20px);
            margin-left: 20px;
            flex-wrap: wrap;
            @include flexBox;

            .qualified,
            .score,
            .trend {
                &-wrapper {
                    background: #fff;
                    border-radius: 10px;
                    overflow: hidden;
                }
            }

            .qualified {
                &-wrapper {
                    width: 37.64%;
                    height: 406px;
                    max-width: 390px;
                    overflow: visible;
                }

                &-inner {
                    width: 100%;
                    height: calc(100% - 72px);
                    box-sizing: border-box;
                    padding: 28px 36px 26px;

                    .no-data--empty {
                        img {
                            width: 160px;
                        }
                    }

                    .paper-box {
                        width: 100%;
                        height: 24px;
                        @include flexBox(center);

                        .paper-name {
                            max-width: calc(100% - 24px);
                            font-size: 16px;
                            color: #444;
                        }

                        .more {
                            width: 24px;
                            padding: 20px 0;
                            flex-shrink: 0;
                            margin-left: 10px;
                            position: relative;

                            .iconfont {
                                display: inline-block;
                                width: 24px;
                                height: 24px;
                                border-radius: 50%;
                                background: #6c4ecb;
                                color: #fff;
                                font-size: 18px;
                                text-align: center;
                                line-height: 24px;
                                cursor: pointer;
                            }
                        }

                        .paper-list {
                            width: 370px;
                            max-height: 0;
                            border-radius: 10px;
                            background-color: #f7f7ff;
                            box-shadow: 0px 0px 18px 0px rgba(67, 67, 67, 0.29);
                            position: absolute;
                            top: 58px;
                            left: 50%;
                            z-index: 20;
                            transform: translateX(-50%);
                            box-sizing: border-box;
                            padding: 0 16px;
                            opacity: 0;

                            h5 {
                                height: 0;
                                overflow: hidden;
                                line-height: 72px;
                                color: #3f3f3f;
                                text-align: center;
                                border-bottom: 1px solid rgba($color: #6b6b6b, $alpha: .07);
                            }

                            .list-wrapper {
                                width: calc(100% + 10px);
                                max-height: 0;
                                overflow: hidden;
                                box-sizing: border-box;
                                padding: 20px 14px 0 4px;

                                .list-item {
                                    width: 100%;
                                    height: 80px;
                                    margin-bottom: 14px;
                                    border-radius: 10px;
                                    border: 2px solid #fff;
                                    box-sizing: border-box;
                                    padding: 0 14px;
                                    background: #fff;
                                    @include flexBox;

                                    &:not(.current):hover {
                                        border-color: #6c4ecb;
                                    }

                                    &.current {
                                        border-color: #e8eaf3;
                                        background: #f0edfa;

                                        .iconfont {
                                            background: #fff;
                                            color: #e8eaf3;
                                        }
                                    }

                                    &:last-child {
                                        margin-bottom: 0;
                                    }

                                    .iconfont {
                                        display: inline-block;
                                        width: 50px;
                                        height: 58px;
                                        background: #e8eaf3;
                                        border-radius: 10px;
                                        text-align: center;
                                        line-height: 58px;
                                        color: #fff;
                                        font-size: 42px;
                                        flex-shrink: 0;
                                    }

                                    .paper-info {
                                        width: calc(100% - 62px);
                                        margin-left: 12px;
                                        line-height: 24px;
                                        flex-direction: column;
                                        @include flexBox(center, flex-start);

                                        .name {
                                            width: 100%;
                                            display: inline-block;
                                            color: #0b0b0b;
                                        }

                                        .time {
                                            color: #949495;
                                        }
                                    }
                                }
                            }

                            &::after {
                                content: '\e636';
                                font-family: 'iconfont';
                                color: #f7f7ff;
                                position: absolute;
                                top: -8px;
                                left: 50%;
                                transform: translateX(-50%);
                            }

                            &.show {
                                max-height: 394px;
                                opacity: 1;
                                padding-bottom: 20px;

                                h5 {
                                    height: 72px;
                                }

                                .list-wrapper {
                                    max-height: 300px;
                                    overflow-y: auto;
                                }
                            }
                        }
                    }

                    .students-box {
                        width: 274px;
                        height: 46px;
                        margin: 38px auto 0;
                        align-content: flex-start;
                        @include flexBox;

                        .el-avatar {
                            border: 1px solid #ececee;
                        }

                        .more {
                            width: 46px;
                            padding: 20px 0;
                            position: relative;

                            .iconfont {
                                display: inline-block;
                                width: 46px;
                                height: 46px;
                                border-radius: 50%;
                                background: #6c4ecb;
                                color: #fff;
                                font-size: 18px;
                                text-align: center;
                                line-height: 44px;
                                box-sizing: border-box;
                                border: 1px solid #ececee;
                                cursor: pointer;
                            }

                            &-students {
                                width: 340px;
                                max-height: 0;
                                border-radius: 10px;
                                background-color: #f7f7ff;
                                box-shadow: 0px 0px 18px 0px rgba(67, 67, 67, 0.29);
                                position: absolute;
                                top: 80px;
                                left: 50%;
                                z-index: 20;
                                transform: translateX(-50%);
                                box-sizing: border-box;
                                padding: 0 12px 0 48px;
                                opacity: 0;

                                &::after {
                                    content: '\e636';
                                    font-family: 'iconfont';
                                    color: #f7f7ff;
                                    position: absolute;
                                    top: -8px;
                                    left: 50%;
                                    transform: translateX(-50%);
                                }

                                .list-wrapper {
                                    width: calc(100% + 10px);
                                    max-height: 0;
                                    overflow: hidden;

                                    .list-item {
                                        width: 100%;
                                        height: 62px;
                                        margin-bottom: 34px;
                                        box-sizing: border-box;
                                        @include flexBox;

                                        &:last-child {
                                            margin-bottom: 0;
                                        }

                                        p {
                                            font-size: 16px;
                                            color: #2b2b2b;
                                            margin-left: 10px;
                                        }
                                    }
                                }

                                &.show {
                                    max-height: 494px;
                                    opacity: 1;
                                    padding-bottom: 28px;
                                    padding-top: 28px;

                                    h5 {
                                        height: 72px;
                                    }

                                    .list-wrapper {
                                        max-height: 438px;
                                        overflow-y: auto;
                                    }
                                }
                            }
                        }
                    }
                }

                &-rate {
                    width: 122px;
                    height: 122px;
                    position: relative;
                    margin: 48px auto 0;

                    .center-text {
                        width: 122px;
                        height: 122px;
                        position: absolute;
                        left: 0;
                        top: 0;
                        flex-direction: column;
                        @include flexBox(center);

                        .percentage {
                            font-size: 20px;
                            line-height: 1em;
                            color: #ee4f65;

                            span {
                                font-size: 14px;
                            }

                        }
                    }
                }
            }

            .score {
                &-wrapper {
                    width: calc(62.36% - 20px);
                    height: 406px;
                    margin-left: 20px;
                    overflow: visible;
                }

                &-inner {
                    width: 100%;
                    height: calc(100% - 72px);
                    box-sizing: border-box;
                    padding: 28px 36px 26px;

                    .no-data--empty {
                        img {
                            width: 160px;
                        }
                    }

                    .homework-box {
                        width: 100%;
                        height: 24px;
                        margin-bottom: 10px;
                        @include flexBox(center);

                        .homework-name {
                            max-width: 300px;
                            font-size: 16px;
                            color: #444;
                        }

                        .more {
                            width: 24px;
                            padding: 20px 0;
                            flex-shrink: 0;
                            margin-left: 26px;
                            position: relative;

                            .iconfont {
                                display: inline-block;
                                width: 24px;
                                height: 24px;
                                border-radius: 50%;
                                background: #6c4ecb;
                                color: #fff;
                                font-size: 18px;
                                text-align: center;
                                line-height: 24px;
                                cursor: pointer;
                            }
                        }

                        .homework-list {
                            width: 340px;
                            max-height: 0;
                            border-radius: 10px;
                            background-color: #f7f7ff;
                            box-shadow: 0px 0px 18px 0px rgba(67, 67, 67, 0.29);
                            position: absolute;
                            top: 58px;
                            left: 50%;
                            z-index: 20;
                            transform: translateX(-50%);
                            box-sizing: border-box;
                            padding: 0 16px;
                            opacity: 0;

                            h5 {
                                height: 0;
                                overflow: hidden;
                                line-height: 72px;
                                color: #3f3f3f;
                                text-align: center;
                                border-bottom: 1px solid rgba($color: #6b6b6b, $alpha: .07);
                            }

                            .list-wrapper {
                                width: calc(100% + 10px);
                                max-height: 0;
                                overflow: hidden;
                                box-sizing: border-box;
                                padding: 20px 14px 0 4px;

                                .list-item {
                                    width: 100%;
                                    height: 80px;
                                    margin-bottom: 14px;
                                    border-radius: 10px;
                                    border: 2px solid #fff;
                                    box-sizing: border-box;
                                    padding: 0 14px;
                                    background: #fff;
                                    @include flexBox;

                                    &:not(.current):hover {
                                        border-color: #6c4ecb;
                                    }

                                    &.current {
                                        border-color: #e8eaf3;
                                        background: #f0edfa;

                                        .iconfont {
                                            background: #fff;
                                            color: #e8eaf3;
                                        }
                                    }

                                    &:last-child {
                                        margin-bottom: 0;
                                    }

                                    .iconfont {
                                        display: inline-block;
                                        width: 50px;
                                        height: 58px;
                                        background: #e8eaf3;
                                        border-radius: 10px;
                                        text-align: center;
                                        line-height: 58px;
                                        color: #fff;
                                        font-size: 42px;
                                        flex-shrink: 0;
                                    }

                                    .homework-info {
                                        width: calc(100% - 62px);
                                        margin-left: 12px;
                                        line-height: 24px;
                                        flex-direction: column;
                                        @include flexBox(center, flex-start);

                                        .name {
                                            width: 100%;
                                            display: inline-block;
                                            color: #0b0b0b;
                                        }

                                        .time {
                                            color: #949495;
                                        }
                                    }
                                }
                            }

                            &::after {
                                content: '\e636';
                                font-family: 'iconfont';
                                color: #f7f7ff;
                                position: absolute;
                                top: -8px;
                                left: 50%;
                                transform: translateX(-50%);
                            }

                            &.show {
                                max-height: 394px;
                                opacity: 1;
                                padding-bottom: 20px;

                                h5 {
                                    height: 72px;
                                }

                                .list-wrapper {
                                    max-height: 300px;
                                    overflow-y: auto;
                                }
                            }
                        }
                    }
                }

                &-echarts {
                    width: 100%;
                    height: calc(100% - 34px);
                }
            }

            .trend {
                &-wrapper {
                    width: 100%;
                    height: 548px;
                    margin-top: 28px;
                }

                &-inner {
                    width: 100%;
                    height: calc(100% - 72px);
                    box-sizing: border-box;
                    padding: 0 36px 0 54px;
                }

                &--tabs {
                    width: calc(100% - 40px);
                    height: 82px;
                    margin-left: 40px;
                    border-bottom: 1px solid rgba($color: #626262, $alpha: 0.08);
                    box-sizing: border-box;
                    padding-top: 14px;
                    @include flexBox(space-between);

                    .charts-type {
                        width: 240px;
                        height: 68px;
                        position: relative;
                        margin-top: 6px;
                        margin-right: auto;
                        @include flexBox;

                        &:after {
                            content: '';
                            width: 38px;
                            height: 5px;
                            border-radius: 2.5px;
                            background: #58419c;
                            position: absolute;
                            left: 12px;
                            bottom: 0;
                            @include defaultAni;
                        }

                        &.exam::after {
                            transform: translateX(160px);
                        }

                        li {
                            cursor: pointer;
                            color: #373639;

                            &:first-child {
                                padding-right: 52px;
                            }

                            &:last-child {
                                padding-left: 52px;
                                position: relative;

                                &::after {
                                    content: '';
                                    height: 20px;
                                    border-left: 1px solid rgba($color: #7a7a7a, $alpha: 0.2);
                                    position: absolute;
                                    left: 0;
                                    top: 50%;
                                    transform: translateY(-50%);
                                }
                            }

                            &.current,
                            &:not(.current):hover {
                                color: #6c4ecb;
                            }
                        }
                    }

                }

                &--echarts {
                    width: 100%;
                    height: 100%;
                    @include flexBox(center);

                    &--wrapper {
                        width: 100%;
                        height: calc(100% - 82px);
                        margin-top: 20px;
                        @include flexBox(center);
                    }
                }
            }
        }
    }
}
</style>

<template>
    <section class="study-analysis">
        <div class="page-head">
            <img class="bitmap" src="@assets/images/bitmap-head.png" alt="">
            <div class="head-wrapper column">
                <breadcrumb />
                <div class="wrap-plate">
                    <ul class="page-tab bold">
                        <li class="tab-item " :class="{ current: searchForm.sysub_id == item.sysub_id }"
                            @click="switchSubject(index)" v-for="(item, index) in teachSubject.slice(0, 5)"
                            :key="item.sysub_id">
                            {{ item.sysub_name }}
                        </li>
                    </ul>
                    <el-dropdown class="more-subject" placement="bottom-start" v-if="teachSubject.length > 5">
                        <p class="iconfont">&#xe8aa;</p>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-for="(item, index) in teachSubject.slice(5, teachSubject.length)"
                                :key="item.sysub_id" @click.native="switchSubject(index + 5)">
                                {{ item.sysub_name }}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <el-button type="custom_primary" size="medium" @click="$router.go(-1)">
                        返回
                    </el-button>
                </div>
            </div>
        </div>
        <div class="page-inner">
            <div class="inner-box">
                <!-- 排行榜 -->
                <div class="ranking-wrapper">
                    <h4 class="box-head">
                        <span class="bold">排行榜</span>
                    </h4>
                    <div class="ranking-table" :class="{ 'no-data': $isEmpty(analysisData.ranking_data) }">
                        <template v-if="!$isEmpty(analysisData.ranking_data)">
                            <div class="table-head--wrapper">
                                <ul class="table-head">
                                    <li @click="changeSort('order_stexa_score')">
                                        最近考试成绩
                                        <span class="iconfont sort"
                                            :class="{ asc: searchForm.order_stexa_score == 'asc', desc: searchForm.order_stexa_score == 'desc' }">
                                            <i>&#xe655;</i>
                                            <i>&#xe654;</i>
                                        </span>
                                    </li>
                                    <li @click="changeSort('order_sthom_wor_score')">
                                        最近作业成绩
                                        <span class="iconfont sort"
                                            :class="{ asc: searchForm.order_sthom_wor_score == 'asc', desc: searchForm.order_sthom_wor_score == 'desc' }">
                                            <i>&#xe655;</i>
                                            <i>&#xe654;</i>
                                        </span>
                                    </li>
                                    <li @click="changeSort('order_quesion_count')">
                                        错题数量
                                        <span class="iconfont sort"
                                            :class="{ asc: searchForm.order_quesion_count == 'asc', desc: searchForm.order_quesion_count == 'desc' }">
                                            <i>&#xe655;</i>
                                            <i>&#xe654;</i>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div class="table-body--wrapper">
                                <ul class="table-body">
                                    <li class="table-tr" v-for="(item, index) in analysisData.ranking_data"
                                        :key="item.stuser_id">
                                        <div class="user">
                                            <el-avatar :size="62" :src="formatfile(item.stuser_image)">
                                                <img src="@assets/images/empty_avatar.png" />
                                            </el-avatar>
                                            <p>
                                                <b class="bold name line-text--1st"
                                                    :title="item.stuser_name.length > 6 && item.stuser_name || ''">
                                                    {{ item.stuser_name }}
                                                </b>
                                                <span>
                                                    第<b class="pf_bold">{{ index + 1 }}</b>名
                                                </span>
                                            </p>
                                        </div>
                                        <p class="study">
                                            <span class="score-num pf_bold">{{ item.stexa_score || 0 }}</span>
                                            <span class="level">
                                                <b class="bold">{{ item.sthom_wor_grade }}</b>
                                                <span>{{ item.sthom_wor_score || 0 }}</span>
                                            </span>
                                            <span class="wrong-num pf_bold">{{ item.quesion_count || 0 }}</span>
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </template>
                        <div class="no-data--empty" v-if="$isEmpty(analysisData.ranking_data)">
                            <img src="@assets/images/no-data3.png" alt="">
                            <p>暂无排行数据哦~</p>
                        </div>
                    </div>
                </div>
                <div class="right-box">
                    <!-- 考试合格率 -->
                    <div class="qualified-wrapper">
                        <h4 class="box-head">
                            <span class="bold">考试合格率</span>
                        </h4>
                        <div class="qualified-inner" :class="{ 'no-data': $isEmpty(analysisData.examination_data) }">
                            <template v-if="!$isEmpty(analysisData.examination_data)">
                                <div class="paper-box">
                                    <p class="paper-name line-text--1st" :title="currentExam.tepap_title">{{ currentExam.tepap_title }}</p>
                                    <div class="more" ref="morePaper" @click="morePaper"
                                        v-if="analysisData.examination_data.length > 1">
                                        <i class="iconfont">&#xe649;</i>
                                        <div class="paper-list" :class="{ show: showMorePaper }" @click.stop="">
                                            <h5 class="bold">选择试卷</h5>
                                            <ul class="list-wrapper">
                                                <li class="list-item"
                                                    :class="{ current: item.teexa_id == currentExam.teexa_id }"
                                                    v-for="item in analysisData.examination_data" :key="item.teexa_id"
                                                    @click="changeExam(item)">
                                                    <i class="iconfont">&#xe64e;</i>
                                                    <p class="paper-info">
                                                        <span class="name line-text--1st">{{ item.tepap_title }}</span>
                                                        <span class="time">
                                                            考试时间：
                                                            {{ item.teexa_starttime }}~{{ item.teexa_endtime |
                                                                formatTime('HH:mm') }}
                                                        </span>
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="qualified-rate" v-if="currentExam.pass_data">
                                    <el-progress class="percentage-progress danger" :width="122" :stroke-width="10"
                                        type="circle" stroke-linecap="butt"
                                        :percentage="currentExam.pass_data.pass_rate || 0" :show-text="false" />
                                    <div class="center-text">
                                        <p class="pf_bold percentage">
                                            {{ currentExam.pass_data.pass_rate || 0 }}<span class="pf">%</span>
                                        </p>
                                    </div>
                                </div>
                                <ul class="students-box" v-if="currentExam.pass_data">
                                    <li class="student" v-for="item in currentExam.pass_data.se_data.slice(0, 7)"
                                        :key="item.stuser_id" :style="{ width: `calc(100% / 7)` }">
                                        <el-avatar :size="46" :src="formatfile(item.stuser_image)">
                                            <img src="@assets/images/empty_avatar.png" />
                                        </el-avatar>
                                    </li>
                                    <li class="student" v-if="currentExam.pass_data.se_data.length > 7"
                                        :style="{ width: `calc(100% / 7)` }">
                                        <!-- 所有学生 -->
                                        <div class="more" ref="moreStudent" @click="moreStudent">
                                            <i class="iconfont">&#xe8aa;</i>
                                            <div class="more-students" :class="{ show: showMoreStudent }" @click.stop="">
                                                <ul class="list-wrapper">
                                                    <li class="list-item" v-for="item in currentExam.pass_data.se_data"
                                                        :key="item.stuser_id">
                                                        <el-avatar :size="62" :src="formatfile(item.stuser_image)">
                                                            <img src="@assets/images/empty_avatar.png" />
                                                        </el-avatar>
                                                        <p>{{ item.stuser_name }}</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </template>
                            <div class="no-data--empty" v-if="$isEmpty(analysisData.examination_data)">
                                <img src="@assets/images/no-data3.png" alt="">
                                <p>暂无数据哦~</p>
                            </div>
                        </div>
                    </div>
                    <!-- 作业成绩情况 -->
                    <div class="score-wrapper">
                        <h4 class="box-head">
                            <span class="bold">作业成绩情况</span>
                        </h4>
                        <div class="score-inner" :class="{ 'no-data': $isEmpty(analysisData.homework_data) }">
                            <template v-if="!$isEmpty(analysisData.homework_data)">
                                <div class="homework-box">
                                    <p class="homework-name line-text--1st" :title="currentHomeWork.teles_hom_title">{{ currentHomeWork.teles_hom_title }}</p>
                                    <div class="more" ref="moreHomework" @click="moreHomework"
                                        v-if="analysisData.homework_data.length > 1">
                                        <i class="iconfont">&#xe649;</i>
                                        <div class="homework-list" :class="{ show: showMoreHomework }" @click.stop="">
                                            <h5 class="bold">选择作业</h5>
                                            <ul class="list-wrapper">
                                                <li class="list-item"
                                                    :class="{ current: item.teles_hom_id == currentHomeWork.teles_hom_id }"
                                                    v-for="item in analysisData.homework_data" :key="item.teles_hom_id"
                                                    @click="changeHomework(item)">
                                                    <i class="iconfont">&#xe64b;</i>
                                                    <p class="homework-info">
                                                        <span class="name line-text--1st" :title="item.teles_hom_title">{{ item.teles_hom_title }}</span>
                                                        <span class="time">
                                                            下发时间：{{ item.teles_hom_assigntime }}
                                                        </span>
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="score-echarts" ref="score"></div>
                            </template>
                            <div class="no-data--empty" v-if="$isEmpty(analysisData.homework_data)">
                                <img src="@assets/images/no-data3.png" alt="">
                                <p>暂无数据哦~</p>
                            </div>
                        </div>
                    </div>
                    <div class="trend-wrapper">
                        <h4 class="box-head">
                            <span class="bold">平均成绩趋势</span>
                        </h4>
                        <div class="trend-inner">
                            <div class="trend--tabs">
                                <ul class="charts-type" :class="{ exam: searchForm.avg_type == 1 }">
                                    <li :class="{ current: searchForm.avg_type == 2 }" @click="changeTrend(2)">作业成绩</li>
                                    <li :class="{ current: searchForm.avg_type == 1 }" @click="changeTrend(1)">考试成绩</li>
                                </ul>
                            </div>
                            <div class="trend--echarts--wrapper" :class="{ 'no-data': $isEmpty(analysisData.chart_data) }">
                                <div v-if="!$isEmpty(analysisData.chart_data)" class="trend--echarts" ref="trend"></div>
                                <div class="no-data--empty" v-if="$isEmpty(analysisData.chart_data)">
                                    <img src="@assets/images/no-data3.png" alt="">
                                    <p>暂无数据哦~</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>



<script>
import { $studyAnalysis } from "@api/home";
import { $getTeachSubject } from "@api/teaching";
import { formatFile } from "@utils"
export default {
    name: 'home_studyAnalysis',
    computed: {
        formatfile() {
            return function (url) {
                return formatFile(url)
            }
        }
    },
    data() {
        return {
            tabIndex: 0,
            searchForm: {
                avg_type: 2,
            },
            teachSubject: [], // 教师科目表数据源
            analysisData: [], // 分析数据
            currentExam: {},
            currentHomeWork: {},
            trendChange: true,
            scoreChange: true,
            rankingSort: false,
            showMorePaper: false,
            showMoreStudent: false,
            showMoreHomework: false,
        }
    },
    mounted() {
        document.addEventListener("click", (e) => {
            let el1 = this.$refs.morePaper;
            let el2 = this.$refs.moreStudent;
            let el3 = this.$refs.moreHomework;

            if (el1 && !el1.contains(e.target) && this.showMorePaper) {
                this.resetMoreData(1);
            }
            if (el2 && !el2.contains(e.target) && this.showMoreStudent) {
                this.resetMoreData(2);
            }
            if (el3 && !el3.contains(e.target) && this.showMoreHomework) {
                this.resetMoreData(3);
            }
        });
    },
    created() {
        let { class: sccla_id } = this.$route.params;
        this.searchForm.sccla_id = sccla_id;
        this.getSubject();
    },
    methods: {
        /** 获取科目数据 */
        async getSubject() {
            let { data } = await $getTeachSubject(this.searchForm.sccla_id);
            this.teachSubject = data;
            /* 默认选中科目 */
            if (!this.$isEmpty(data)) {
                this.teachSubject.map((item, index) => {
                    if (index == 0) {
                        this.searchForm.sysub_id = item.sysub_id;
                    }
                });
                this.getData();
            }
            this.$forceUpdate();
        },
        /** 获取统计数据 */
        async getData() {
            let { data } = await $studyAnalysis(this.searchForm);
            this.analysisData = data;
            if (!this.rankingSort) {
                this.currentExam = data.examination_data[0] || {};
                this.currentHomeWork = data.homework_data[0] || {};
            }
            this.rankingSort = false;
            this.$nextTick(() => {
                if (this.scoreChange && !this.$isEmpty(this.currentHomeWork)) this.scoreEcharts();
                if (this.trendChange) this.trendEcharts();
                this.trendChange = this.scoreChange = false;
                this.$forceUpdate();
            })
        },
        /** 切换科目 */
        switchSubject(index) {
            this.searchForm.sysub_id = this.teachSubject[index].sysub_id;
            if (index > 4) {
                let firData = this.teachSubject[index];
                let replaceData = this.teachSubject[4];
                this.teachSubject[4] = { ...firData };
                this.teachSubject[index] = { ...replaceData };
            }
            delete this.searchForm.order_stexa_score;
            delete this.searchForm.order_sthom_wor_score;
            delete this.searchForm.order_quesion_count;
            this.trendChange = true;
            this.scoreChange = true;
            this.rankingSort = false;
            this.getData();
        },
        /** 切换考试数据 */
        changeExam(data) {
            this.currentExam = data;
            this.resetMoreData(1);
            this.$forceUpdate();
        },
        /** 切换考试数据 */
        changeHomework(data) {
            this.currentHomeWork = data;
            this.resetMoreData(3);
            this.scoreEcharts();
            this.$forceUpdate();
        },
        /**
         * 切换数据排序
         * @param {string} el 排序根据
         */
        changeSort(el) {
            this.rankingSort = true;
            if (!this.searchForm[el]) {
                this.searchForm[el] = 'desc'
            } else {
                if (this.searchForm[el] == 'desc') this.searchForm[el] = 'asc';
                else delete this.searchForm[el]
            }
            this.getData();
        },
        /**
         * 切换趋势图表类型
         * @param {number} val 趋势图表类型 
         */
        changeTrend(val) {
            this.searchForm.avg_type = val;
            this.trendChange = true;
            this.getData();
        },
        /** 作业成绩情况图表 */
        scoreEcharts() {
            let echarts = this.$echarts.init(this.$refs.score);
            echarts.resize();
            echarts.clear();
            let seriesData = []
            this.currentHomeWork.homework_data.map(item => {
                let series_item = {
                    value: item.count,
                    name: `${item.sthom_wor_grade}  ${item.count}次`
                }
                seriesData.push(series_item)
            });
            let option = {
                color: ['#f66478', '#6c4ecb', '#fcb332', '#2ac293'],
                tooltip: {
                    show: false
                },
                legend: {
                    itemGap: 30,
                    icon: 'circle',
                    show: true,
                    bottom: 0,
                    itemWidth: 10,
                    itemHeight: 10,
                    textStyle: {
                        fontSize: 14,
                        color: '#333333',
                        rich: {
                            a: {
                                verticalAlign: 'middle',
                            },
                        },
                        lineHeight: 10,
                        padding: [0, 0, -4, 4],
                    },
                },
                grid: {
                    show: false,
                    top: '0px',
                    bottom: '0px',
                },
                series: [
                    {
                        type: 'pie',
                        radius: [60, 82],
                        top: -20,
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                        },
                        labelLine: {
                            show: false
                        },
                        data: seriesData,
                    }
                ]
            };
            echarts.setOption(option)
        },
        /** 成绩趋势图表 */
        trendEcharts() {
            let echarts = this.$echarts.init(this.$refs.trend);
            echarts.resize();
            echarts.clear();
            let { chart_data } = this.analysisData
            let option = {
                tooltip: {
                    trigger: 'axis',
                    backgroundColor: '#6340c8',
                    extraCssText: 'border-radius: 8px',
                    borderColor: '#6340c8',
                    padding: [5, 10],
                    formatter: '{c}分',
                    textStyle: {
                        color: '#fff',
                        height: '24px',
                        fontSize: 14,
                        fontFamily: 'PingFang SC'
                    },
                    axisPointer: {
                        lineStyle: {
                            color: 'rgba(152, 126, 232, 0.26)'
                        }
                    },
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: chart_data.x_data,
                    offset: 18,
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        color: '#282828',
                        fontFamily: 'PingFang SC'
                    }
                },
                yAxis: {
                    type: 'value',
                    scale: true,
                    offset: 5,
                    max: 120,
                    min: 0,
                    interval: 10,
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    splitLine: {
                        lineStyle: {
                            // 使用深浅的间隔色
                            color: ['rgba(233,233,244,0.5)'],
                            type: 'dashed'
                        }
                    },
                    axisLabel: {
                        color: '#282828',
                        fontFamily: 'PingFang SC'
                    }
                },
                grid: {
                    show: false,
                    right: '40px',
                    left: '40px',
                    top: '20px',
                    bottom: '100px'
                },
                series: [
                    {
                        type: 'line',
                        data: chart_data.y_data,
                        symbol: 'circle',
                        showSymbol: false,
                        symbolSize: 10,
                        smooth: true,
                        itemStyle: {
                            color: '#987ee8',
                            borderWidth: 2,
                            borderColor: '#fff',
                            shadowColor: 'rgba(99, 64, 200, 0.53)',
                            shadowBlur: 20
                        },
                        areaStyle: {
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: 'rgba(152, 126, 232, 0.8)'
                                },
                                {
                                    offset: 0.5,
                                    color: 'rgba(152, 126, 232, 0)'
                                }
                            ])
                        },
                    }
                ],
            };
            echarts.setOption(option);
        },
        /** 更多试卷 */
        morePaper() {
            if (this.showMorePaper) return this.resetMoreData(1)
            this.showMorePaper = true;
        },
        /** 更多学生 */
        moreStudent() {
            if (this.showMoreStudent) return this.resetMoreData(2)
            this.showMoreStudent = true;
        },
        /** 更多作业 */
        moreHomework() {
            if (this.showMoreHomework) return this.resetMoreData(3)
            this.showMoreHomework = true;
        },
        /** 重置数据 */
        resetMoreData(type) {
            switch (type) {
                case 1:
                    this.showMorePaper = false; // 关闭下拉
                    break;
                case 2:
                    this.showMoreStudent = false; // 关闭下拉
                    break;
                case 3:
                    this.showMoreHomework = false; // 关闭下拉
                    break;
                default:
                    break;
            }
        },
    },
}
</script>